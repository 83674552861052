import styled from 'styled-components'

export const Max768Wrapper = styled.div`
	@media (min-width: 767px) {
		display: none;
	}
`

export const Min768Wrapper = styled.div`
	@media (max-width: 768px) {
		display: none;
	}
`