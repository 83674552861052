import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'
import gsap from 'gsap'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useTranslation } from 'react-i18next'

import Typography from '../common/Typography'
import GradientCanvas from '../main/GradientCanvas'
import zIndexes from '../../lib/styles/zIndexes'
import { ColorType } from '../../context/InvertColorsContext'

interface ISolutionIntroSectionProps {
	innerHeight: number
	innerWidth: number
	invertColors: (colorType: ColorType) => void
}

function SolutionIntroSection({
	innerHeight,
	innerWidth,
	invertColors,
}: ISolutionIntroSectionProps) {
	const breakpoints = useBreakpoint()
	const circleRef = useRef<HTMLDivElement>(null)
	const pinRef = useRef<HTMLDivElement>(null)
	const imageboxRef = useRef<HTMLDivElement>(null)
	const frontTextRef = useRef<HTMLDivElement>(null)
	const backTextRef = useRef<HTMLDivElement>(null)
	const { t } = useTranslation()

	useEffect(() => {
		if (
			!pinRef.current ||
			!imageboxRef.current ||
			!frontTextRef.current ||
			!backTextRef.current
		) {
			return
		}

		const isDesktop = innerWidth > 1216

		const timeline = gsap.timeline({
			scrollTrigger: {
				trigger: pinRef.current,
				start: 'top top',
				end: isDesktop ? innerHeight * 1 : innerHeight * 3,
				scrub: true,
				pin: pinRef.current,
			},
		})
		timeline
			.to(imageboxRef.current, {
				scale: 3,
				duration: 2,
			})
			.to(frontTextRef.current, { opacity: 0 })
			.to(backTextRef.current, { opacity: 1 })
			.to(
				imageboxRef.current,
				{
					scale: 8,
					duration: 2,
					borderRadius: 0,
				},
				'<'
			)
		return () => {
			timeline.kill()
		}
	}, [imageboxRef.current, circleRef.current, pinRef.current])

	useEffect(() => {
		invertColors(ColorType.WHITE)
	}, [])

	const gradientColors = {
		color1: '#05F300',
		color2: '#FFC700',
		color3: '#EEFF85',
		color4: '#78FF75',
	}

	return (
		<Block>
			<div className="pin" ref={pinRef}>
				<Content>
					<ImageBlock>
						<div className="imageBox" ref={imageboxRef}>
							<div className="circle" ref={circleRef}>
								<GradientCanvas
									hasShape={false}
									gradientColors={gradientColors}
								/>
							</div>
						</div>
					</ImageBlock>
					<Introduction>
						<div className="frontText" ref={frontTextRef}>
							<Typography.Display
								size="xlarge"
								fontWeight="bold"
								className="text"
							>
								{breakpoints.md ? (
									<>{t('SolutionIntroSection.heading1')} </>
								) : (
									<>{t('SolutionIntroSection.heading1sm')}</>
								)}
							</Typography.Display>
						</div>
						<div className="backText" ref={backTextRef}>
							<Typography.Display
								size="xlarge"
								fontWeight="bold"
								className="text"
							>
								{breakpoints.md ? (
									<>{t('SolutionIntroSection.heading2')}</>
								) : (
									<>{t('SolutionIntroSection.heading3')} </>
								)}
							</Typography.Display>
						</div>
					</Introduction>
				</Content>
			</div>
		</Block>
	)
}

const Block = styled.section`
	width: 100%;
	margin: 0 auto;
	z-index: ${zIndexes.solutionIntroSection};

	.pin {
		min-height: 100vh;
	}
	${down('xs')} {
		padding-top: 2.625rem;
	}
`

const Content = styled.div`
	width: 100%;
	height: 100vh;
	position: relative;
	height: 100vh;

	.description {
		width: 30.5rem;
	}
`

const Introduction = styled.section`
	width: 100%;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	word-break: keep-all;

	.frontText,
	.backText {
		display: block;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 80px;
	}

	.frontText {
		opacity: 1;
		width: 100%;
		${down('xs')} {
			width: auto;
		}
		.text {
			font-size: 80px;
			/* min-width: 500px; */
			display: inline-block;
			line-height: 1.2;
			letter-spacing: -2px;
			${down('xs')} {
				font-size: 56px;
			}
		}
	}

	.backText {
		color: #fff;
		z-index: ${zIndexes.solutionIntroBackText};
		opacity: 0;
		transition: all 0.5s;
		width: 100%;
		max-width: 850px;
		${down('md')} {
			min-width: 600px;
		}
		${down('sm')} {
			.text {
				font-size: 72px;
				line-height: 88px;
				display: block;
			}
		}
		${down('xs')} {
			.text {
				font-size: 44px;
				line-height: 56px;
			}
			min-width: 300px;
			text-align: center;
		}
		.rightDescription {
			padding-top: 32px;
			padding-left: 2rem;
			padding-right: 2rem;
			${down('sm')} {
				padding-left: 0;
				padding-right: 0;
			}
			${down('sm')} {
				font-size: 32px;
				line-height: 40px;
			}
			${down('xs')} {
				font-size: 20px;
				line-height: 28px;
			}
		}
	}
`

const ImageBlock = styled.div`
	position: absolute;
	width: 386px;
	height: 386px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: ${zIndexes.solutionIntroImage};
	animation: fadeIn 1s;

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	${down('xs')} {
		width: 320px;
		height: 320px;
	}

	.imageBox {
		height: 386px;
		overflow: hidden;
		border-radius: 50%;

		${down('xs')} {
			height: 320px;
		}

		.circle {
			height: 386px;
			overflow: hidden;

			${down('xs')} {
				height: 320px;
			}
		}
	}
`
export default SolutionIntroSection
