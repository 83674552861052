import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { graphql, useStaticQuery } from 'gatsby'
import Image from '../common/Image'
import Arrow from '../../assets/icons/chevron.svg'
import MoreButton from '../common/MoreButton'

function SolutionFifthSection() {
	const { t } = useTranslation()

	const data = useStaticQuery(graphql`
		query {
			SolutionImage6: file(
				relativePath: { eq: "images/solution/SolutionImage6.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			SolutionImage6_mobile: file(
				relativePath: { eq: "images/solution/SolutionImage6_mobile.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			SolutionImage7: file(
				relativePath: { eq: "images/solution/SolutionImage7.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			SolutionImage8: file(
				relativePath: { eq: "images/solution/SolutionImage8.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<FullWidth>
			<Block>
				<HeadingContainer>
					<Min1440Wrapper>
					<HeadingTitle>
						{t('SolutionFifthSection.headingTitle.1')}
					</HeadingTitle>
					</Min1440Wrapper>
					<Max1440Wrapper>
					<HeadingTitle>
						{t('SolutionFifthSection.headingTitle.max1024')}
					</HeadingTitle>
					</Max1440Wrapper>
					
					<HeadingDescription>
						{t('SolutionFifthSection.headingDescription.1')}
					</HeadingDescription>
				</HeadingContainer>
				<ContentsContainer>
					<ImageAndIntroduceBox>
						<ImageWrapperForDesktop>
							<Image data={{ placeholderImage: data.SolutionImage6 }} />
						</ImageWrapperForDesktop>
						<ImageWrapperForMobileAndtablet>
							<Image data={{ placeholderImage: data.SolutionImage6_mobile }} />
						</ImageWrapperForMobileAndtablet>
						<IntroduceWrapper>
							<IntroduceTitle>
								{t('SolutionFifthSection.introduceTitle.1')}
							</IntroduceTitle>
							<IntroduceDescription>
								{t('SolutionFifthSection.introduceDescription.1')}
							</IntroduceDescription>
						</IntroduceWrapper>
					</ImageAndIntroduceBox>

					<ImageAndIntroduceBox>
						<ImageWrapper>
							<Image data={{ placeholderImage: data.SolutionImage7 }} />
						</ImageWrapper>
						<IntroduceWrapper>
							<IntroduceTitle>
								{t('SolutionFifthSection.introduceTitle.2')}
							</IntroduceTitle>
							<IntroduceDescription>
								{t('SolutionFifthSection.introduceDescription.2')}
							</IntroduceDescription>
						</IntroduceWrapper>
					</ImageAndIntroduceBox>

					<ImageAndIntroduceBox>
						<ImageWrapper>
							<Image data={{ placeholderImage: data.SolutionImage8 }} />
						</ImageWrapper>
						<IntroduceWrapper>
							<IntroduceTitle>
								{t('SolutionFifthSection.introduceTitle.3')}
							</IntroduceTitle>
							<IntroduceDescription>
								{t('SolutionFifthSection.introduceDescription.3')}
							</IntroduceDescription>
						</IntroduceWrapper>
					</ImageAndIntroduceBox>
				</ContentsContainer>
				<ButtonContainer>
					<MoreButton link="/contact" text={t('SolutionFifthSection.button.1')} />
				</ButtonContainer>
			</Block>
		</FullWidth>
	)
}

const FullWidth = styled.section`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const Block = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		width: 1008px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		padding-top: 481px;
		gap: 48px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 368px;
		gap: 24px;
	}
	@media (max-width: 768px) {
		padding-top: 182px;
		gap: 24px;
	}
`

const HeadingTitle = styled.div`
	font-weight: 700;
	white-space: pre-wrap;
	@media (min-width: 1440px) {
		font-size: 60px;
		line-height: 72px; /* 120% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		line-height: 56px; /* 116.667% */
	}
	@media (max-width: 768px) {
		font-size: 36px;
		line-height: 44px; /* 122.222% */
	}
`

const HeadingDescription = styled.div`
	font-weight: 400;

	@media (min-width: 1440px) {
		font-size: 20px;
		line-height: 32px; /* 160% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
	@media (max-width: 768px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
`

const ContentsContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		padding-top: 80px;
		gap: 80px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 86px;
		gap: 86px;
	}
	@media (max-width: 768px) {
		padding-top: 86px;
		gap: 64px;
	}
`

const ImageAndIntroduceBox = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		flex-direction: row;
		gap: 32px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		flex-direction: column;
		gap: 24px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 24px;
	}
`

const ImageWrapper = styled.div`
	@media (min-width: 1440px) {
		min-width: 488px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 447px;
	}
	@media (max-width: 768px) {
		width: 322px;
	}
`

const ImageWrapperForDesktop = styled.div`
	@media (min-width: 1440px) {
		min-width: 488px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		display: none;
	}
	@media (max-width: 768px) {
		display: none;
	}
`

const ImageWrapperForMobileAndtablet = styled.div`
	@media (min-width: 1440px) {
		display: none;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 447px;
	}
	@media (max-width: 768px) {
		width: 322px;
	}
`

const IntroduceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const IntroduceTitle = styled.div`
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 32px;
		line-height: 40px; /* 125% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 28px;
		line-height: 36px; /* 128.571% */
	}
	@media (max-width: 768px) {
		font-size: 24px;
		line-height: 32px; /* 133.333% */
	}
`

const IntroduceDescription = styled.div`
	font-weight: 400;

	@media (min-width: 1440px) {
		font-size: 20px;
		line-height: 32px; /* 160% */
		padding-top: 24px;
		white-space: pre-line;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 19px;
		line-height: 28px; /* 155.556% */
		padding-top: 16px;
	}
	@media (max-width: 768px) {
		font-size: 14px;
		line-height: 20px; /* 142.857% */
		padding-top: 16px;
	}
`

const ButtonContainer = styled.div`
	display: flex;
	width: 100%;

	@media (min-width: 1440px) {
		justify-content: center;
		margin-top: 80px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		justify-content: flex-start;
		margin-top: 86px;
	}
	@media (max-width: 768px) {
		justify-content: center;
		margin-top: 50px;
	}
`

const Min1440Wrapper = styled.div`
	@media (max-width: 1439px) {
		display: none;
	}
`

const Max1440Wrapper = styled.div`
	@media (min-width: 1440px) {
		display: none;
	}
`


export default SolutionFifthSection
