import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'

import SEO from '../components/base/SEO'
import Layout from '../components/base/Layout'
import SolutionIntroSection from '../components/tech/SolutionIntroSection'
import SolutionSecondSection from '../components/tech/SolutionSecondSection'
import SolutionCTASection from '../components/tech/SolutionCTASection'
import useWindowSize from '../lib/hooks/useWindowSize'
import InvertColorsContext from '../context/InvertColorsContext'
import SolutionThirdSection from '../components/tech/SolutionThirdSection'
import SolutionFourthSection from '../components/tech/SolutionFourthSection'
import SolutionFifthSection from '../components/tech/SolutionFifthSection'
import SolutionSixthSection from '../components/tech/SolutionSixthSection'
import SolutionSeventhSection from '../components/tech/SolutionSeventhSection'
import SolutionEighthSection from '../components/tech/SolutionEighthSection'
import SolutionNinethSection from '../components/tech/SolutionNinethSection'


const metaData = {
	title: '기술 | Tesser',
	description:
		'의료 텍스트와 의료영상을 함께 다루는, 의료 분야에 최적화된 AI 모델을 연구합니다.',
	keywords:
		'거대 언어모델, LLM, 의료 LLM, 의료 최적화, 의료영상, Segmentation, 의료 Segmentation, 의료 AI, 의료 멀티모달, 멀티모달 인공지능, 데이터바우처, AI바우처, 데이터가공, 데이터전처리, 인공지능솔루션, 헬스케어 데이터, 테서, tesser, AI솔루션, 어노테이션, 세그멘테이션, annotation, segmentation',
}

// 링크: /solution -> /tech로 변경
// 안정성을 위해 컴포넌트 명은 Solution 유지

function Solution() {
	const windowSize = useWindowSize()
	const [innerHeight, setInnerHeight] = useState<number | null>(null)
	const [innerWidth, setInnerWidth] = useState<number | null>(null)

	useEffect(() => {
		if (windowSize.height) {
			setInnerHeight(windowSize.height)
		}
		if (windowSize.width) {
			setInnerWidth(windowSize.width)
		}
	}, [windowSize])

	if (!innerHeight || !innerWidth) {
		return (
			<Layout>
				<SEO metaData={metaData} />
				<Block></Block>
			</Layout>
		)
	}

	return (
		<Layout blackFooter>
			<SEO metaData={metaData} />
			<InvertColorsContext.Consumer>
				{({ invertColors }) => (
					<Block>
						<SolutionIntroSection
							invertColors={invertColors}
							innerHeight={innerHeight}
							innerWidth={innerWidth}
						/>
						<SolutionSecondSection />
						<SolutionThirdSection />
						<SolutionFourthSection />
						<SolutionFifthSection />
						<SolutionSixthSection />
						<SolutionSeventhSection />
						<SolutionEighthSection />
						<SolutionNinethSection />
						<SolutionCTASection />
					</Block>
				)}
			</InvertColorsContext.Consumer>
		</Layout>
	)
}

const Block = styled.div`
	min-height: 100vh;
	margin: 0 auto;
	margin-bottom: 5rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	${down('sm')} {
		margin-bottom: 3rem;
	}
`

export default Solution