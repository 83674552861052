import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { graphql, useStaticQuery } from 'gatsby'
import Image from '../common/Image'

function SolutionEighthSection() {
	const { t } = useTranslation()

	const data = useStaticQuery(graphql`
		query {
			SolutionImage13: file(
				relativePath: { eq: "images/solution/SolutionImage13.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			SolutionImage14: file(
				relativePath: { eq: "images/solution/SolutionImage14.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			SolutionImage15: file(
				relativePath: { eq: "images/solution/SolutionImage15.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<FullWidth>
			<Block>
				<HeadingContainer>
					<HeadingTitle>
						{t('SolutionEighthSection.headingTitle.1')}
					</HeadingTitle>
					<HeadingDescription>
						{t('SolutionEighthSection.headingDescription.1')}
					</HeadingDescription>
				</HeadingContainer>
				<ContentsContainer>
					<ContentsBox>
						<ImageContainer>
							<ContentsImageWrap>
								<Image data={{ placeholderImage: data.SolutionImage13 }} />
							</ContentsImageWrap>
						</ImageContainer>
						<ContentsTextBox>
							<ContentsTitle>
								{t('SolutionEighthSection.contentsTitle.1')}
							</ContentsTitle>
							<ContentsDescription>
								{t('SolutionEighthSection.contentsDescription.1')}
							</ContentsDescription>
						</ContentsTextBox>
					</ContentsBox>
					<ContentsBox>
						<ImageContainer>
							<ContentsImageWrap>
								<Image data={{ placeholderImage: data.SolutionImage14 }} />
							</ContentsImageWrap>
						</ImageContainer>
						<ContentsTextBox>
							<ContentsTitle>
								{t('SolutionEighthSection.contentsTitle.2')}
							</ContentsTitle>
							<ContentsDescription>
								{t('SolutionEighthSection.contentsDescription.2')}
							</ContentsDescription>
						</ContentsTextBox>
					</ContentsBox>
					<ContentsBox>
						<ImageContainer>
							<ContentsImageWrap>
								<Image data={{ placeholderImage: data.SolutionImage15 }} />
							</ContentsImageWrap>
						</ImageContainer>
						<ContentsTextBox>
							<ContentsTitle>
								{t('SolutionEighthSection.contentsTitle.3')}
							</ContentsTitle>
							<ContentsDescription>
								{t('SolutionEighthSection.contentsDescription.3')}
							</ContentsDescription>
						</ContentsTextBox>
					</ContentsBox>
				</ContentsContainer>
			</Block>
		</FullWidth>
	)
}

const FullWidth = styled.section`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const Block = styled.div`
	@media (min-width: 1440px) {
		width: 1008px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		padding-top: 407px;
		gap: 48px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 240px;
		gap: 24px;
	}
	@media (max-width: 768px) {
		padding-top: 128px;
		gap: 24px;
	}
`

const HeadingTitle = styled.div`
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 60px;
		line-height: 72px; /* 120% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		line-height: 56px; /* 116.667% */
	}
	@media (max-width: 768px) {
		font-size: 36px;
		line-height: 44px; /* 122.222% */
		white-space: pre-line;
	}
`

const HeadingDescription = styled.div`
	font-weight: 400;

	@media (min-width: 1440px) {
		font-size: 20px;
		line-height: 32px; /* 160% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
	@media (max-width: 768px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
`

const ContentsContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		padding-top: 80px;
		gap: 104px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 86px;
		gap: 98px;
	}
	@media (max-width: 768px) {
		padding-top: 86px;
		gap: 86px;
	}
`

const ContentsBox = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		gap: 69px;
		flex-direction: row;
		align-items: center;
		padding-left: 37px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		gap: 85px;
		flex-direction: row;
		align-items: center;
		padding-left: 53px;
	}
	@media (max-width: 768px) {
		gap: 16x;
		flex-direction: column;
	}
`

const ImageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const ContentsImageWrap = styled.div`
	width: 100px;
`

const ContentsTextBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`

const ContentsTitle = styled.div`
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 40px;
		line-height: 48px; /* 120% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 28px;
		line-height: 36px; /* 128.571% */
	}
	@media (max-width: 768px) {
		font-size: 20px;
		line-height: 28px; /* 140% */
	}
`

const ContentsDescription = styled.div`
	font-weight: 400;

	@media (min-width: 1440px) {
		font-size: 18px;
		line-height: 24px; /* 133.333% */
		white-space: pre-line;

		padding-right: 230px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 16px;
		line-height: 24px; /* 150% */
	}
	@media (max-width: 768px) {
		font-size: 16px;
		line-height: 24px; /* 150% */
	}
`

export default SolutionEighthSection
