import React from 'react'
import styled from 'styled-components'
import Arrow from '../../assets/icons/chevron.svg'
import { buttonHover } from '../../lib/styles/utils'

interface MoreButtonProps {
	text: string
    link: string
    target?: string
}

function MoreButton({ text, link, target = '_self'}: MoreButtonProps)  {
  return (
    <div>
        <MoreButtonBox href={link} target={target}>
			<MoreButtonText>{text}</MoreButtonText>
            <Arrow />
	    </MoreButtonBox>
    </div>
  )
}

export default MoreButton


const MoreButtonBox = styled.a`
	cursor: pointer;
	display: flex;
	padding: 16px 24px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	background-color: black;
	border-radius: 80px;
	width: max-content;

	${buttonHover}
`

const MoreButtonText = styled.div`
	font-weight: 600;
	line-height: 132%; /* 21.12px */
	color: white;

	@media (min-width: 1440px) {
		font-size: 16px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 14px;
	}
	@media (max-width: 768px) {
		font-size: 12px;
	}
`
