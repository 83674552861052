import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'
import { useTranslation } from 'react-i18next'

import Typography from '../common/Typography'
import ButtonWithIcon from '../common/ButtonWithIcon'
import ShapeAnimation from '../main/ShapeAnimation'
import zIndexes from '../../lib/styles/zIndexes'
import { Link } from 'gatsby'
import useObserver from '../../lib/hooks/useObserver'
import breakpoints from '../../lib/styles/breakpoints'

function SolutionCTASection() {
	const { t } = useTranslation()
	const sectionRef = useRef<HTMLDivElement>(null)
	const [showShapeAnimation, setShowShapeAnimation] = useState(false)

	useObserver(sectionRef, {
		threshold: 0,
		onEnter: () => {
			setShowShapeAnimation(true)
		},
		onLeave: () => {
			setShowShapeAnimation(false)
		},
	})

	return (
		<BlockOuterContainer>
			<Block ref={sectionRef}>
				<TypographyAndText>
					<Typography.Display size="large" fontWeight="bold" className="title">
						{t('SolutionCTASection.heading1')}
					</Typography.Display>
					<Buttons>
						<Link to="/contact">
							<ButtonWithIcon className="cta" fontSize="20px" fontWeight="bold">
								{t('SolutionCTASection.button')}
							</ButtonWithIcon>
						</Link>
					</Buttons>
				</TypographyAndText>
				<ShapeBox>
					{showShapeAnimation && <ShapeAnimation isColored={true} />}
				</ShapeBox>
			</Block>
		</BlockOuterContainer>
	)
}

const BlockOuterContainer = styled.section`
	position: relative;
`

const Block = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100vw;
	padding-top: 11rem;
	margin: 0 auto -80px;
	position: relative;
	background-color: black;

	.title {
		position: relative;
		z-index: 1000;
		word-break: keep-all;
		color: white;
	}
	.subText {
		color: #aaa;
		max-width: 65rem;
		display: block;
		padding-bottom: 16px;
		word-break: keep-all;
	}

	@media (min-width: 1440px) {
		padding: 190px 112px;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		margin-bottom: -40px;
		padding: 100px 112px;
	}

	@media (min-width: 1024px) and (max-width: 1440px) {
		padding: 100px 112px;
		margin-bottom: -70px;
	}

	@media (max-width: 768px) {
		padding: 80px 32px;
		margin-bottom: -40px;
	}
`

const TypographyAndText = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const ShapeBox = styled.div`
	position: relative;
	bottom: -60px;

	.polygon {
		position: relative;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		bottom: -20px;
		.polygon {
			width: 270px;
			height: 280px;
		}
	}

	@media (max-width: 768px) {
		bottom: -30px;
	}

	@media (max-width: 681px) {
		bottom: 0px;
	}
`

const Buttons = styled.div`
	position: relative;
	z-index: 1000;
	display: flex;
	flex-wrap: wrap;

	> * {
		margin-right: 16px;
		margin-bottom: 16px;
	}
	& button {
		background-color: white;
		color: black;
	}

	& button svg path {
		fill: black;
	}

	& button:hover {
		background-color: rgb(208, 208, 208);
	}
`

export default SolutionCTASection
