import React from 'react'
import styled from 'styled-components'
import Img, { FluidObject } from 'gatsby-image'

// styles prop을 CSSProperties 타입으로 변경합니다.
interface ImageProps {
	data: {
		placeholderImage: {
			childImageSharp: {
				fluid: FluidObject | FluidObject[]
			}
		}
	}
	styles?: React.CSSProperties // 여기를 수정합니다.
}

const Image = ({ data, styles }: ImageProps) => {
	if (!data.placeholderImage.childImageSharp?.fluid) {
		return <div>Picture not found</div>
	}

	return (
		<Block style={styles}>
			{' '}
			{/* 여기를 수정합니다. */}
			<Img fluid={data.placeholderImage.childImageSharp.fluid} />
		</Block>
	)
}

const Block = styled.div`
	width: 100%;
	height: 100%;
	max-height: auto;
	max-width: auto;
`

export default React.memo(Image)
