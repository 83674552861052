import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { graphql, useStaticQuery } from 'gatsby'
import Image from '../common/Image'

function SolutionSeventhSection() {
	const { t } = useTranslation()

	const data = useStaticQuery(graphql`
		query {
			SolutionImage12: file(
				relativePath: { eq: "images/solution/SolutionImage12.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<Background>
			<FullWidth>
				<HeadingContainer>
					<TitleText>{t('SolutionSeventhSection.titleText.1')}</TitleText>
				</HeadingContainer>
				<ImageWrapper>
					<Image data={{ placeholderImage: data.SolutionImage12 }} />
				</ImageWrapper>
				<DescriptionContainer>
					<DescriptionBox>
						<DescriptionTitle>
							{t('SolutionSeventhSection.descriptionTitle.1')}
						</DescriptionTitle>
						<DescriptionContent>
							{t('SolutionSeventhSection.descriptionContent.1')}
						</DescriptionContent>
					</DescriptionBox>
					<DescriptionBox>
						<DescriptionTitle>
							{t('SolutionSeventhSection.descriptionTitle.2')}
						</DescriptionTitle>
						<DescriptionContent>
							{t('SolutionSeventhSection.descriptionContent.2')}
						</DescriptionContent>
					</DescriptionBox>
				</DescriptionContainer>
			</FullWidth>
		</Background>
	)
}

const Background = styled.section`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	width: 100vw;
`

const FullWidth = styled.div`
	padding-top: 100px;
	margin: 0 auto;

	@media (min-width: 1440px) {
		width: 1008px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		padding-top: 282px;
		gap: 48px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 178px;
		gap: 24px;
	}
	@media (max-width: 768px) {
		padding-top: 122px;
		gap: 24px;
	}
`

const TitleText = styled.span`
	font-weight: 700;
	white-space: pre-line;

	@media (min-width: 1440px) {
		font-size: 60px;
		line-height: 80px; /* 133.333% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		line-height: 56px; /* 116.667% */
	}
	@media (max-width: 768px) {
		font-size: 36px;
		line-height: 44px; /* 122.222% */
		white-space: pre-line;
	}
`

const ImageWrapper = styled.div`
	@media (min-width: 1440px) {
		padding-top: 80px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		position: absolute;
		left: 0px;
		padding-top: 48px;
		width: 100.5vw; /* 화면 너비와 동일하게 설정 */
	}
	@media (max-width: 768px) {
		position: absolute;
		left: 0px;
		padding-top: 48px;
		width: 100.5vw; /* 화면 너비와 동일하게 설정 */
	}
`

const DescriptionContainer = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		flex-direction: row;
		gap: 136px;
		margin-top: 89px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		flex-direction: column;
		gap: 118px;
		margin-top: calc(72vw + 90px);
	}
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 60px;
		margin-top: calc(72vw + 90px);
	}
`

const DescriptionBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	flex: 1;
`

const DescriptionTitle = styled.div`
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 32px;
		line-height: 40px; /* 125% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 32px;
		line-height: 40px; /* 125% */
	}
	@media (max-width: 768px) {
		font-size: 24px;
		line-height: 32px; /* 133.333% */
	}
`

const DescriptionContent = styled.div`
	@media (min-width: 1440px) {
		font-size: 23px;
		font-weight: 500;
		line-height: 32px; /* 139.13% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 23px;
		font-weight: 500;
		line-height: 32px; /* 139.13% */

		padding-right: 90px;
	}
	@media (max-width: 768px) {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
	}
`

export default SolutionSeventhSection
