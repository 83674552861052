import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { graphql, useStaticQuery } from 'gatsby'
import Image from '../common/Image'
import Arrow from '../../assets/icons/chevron.svg'
import MoreButton from '../common/MoreButton'

function SolutionFourthSection() {
	const { t } = useTranslation()

	const data = useStaticQuery(graphql`
		query {
			SolutionImage3: file(
				relativePath: { eq: "images/solution/SolutionImage3.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			SolutionImage4: file(
				relativePath: { eq: "images/solution/SolutionImage4.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			SolutionImage5: file(
				relativePath: { eq: "images/solution/SolutionImage5.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<FullWidth>
			<Block>
				<ContentsContainer>
					<ImageAndIntroduceBox>
						<IntroduceWrapper>
							<IntroduceTitle>
								{t('SolutionFourthSection.introduceTitle.1')}
							</IntroduceTitle>
							<IntroduceDescription>
								{t('SolutionFourthSection.introduceDescription.1')}
							</IntroduceDescription>
							<IntroduceDescriptionList>
								{t('SolutionFourthSection.introduceDescriptionList.1')}
							</IntroduceDescriptionList>
						</IntroduceWrapper>
					</ImageAndIntroduceBox>

					<ImageAndIntroduceBox>
						<IntroduceWrapper>
							<IntroduceTitle>
								{t('SolutionFourthSection.introduceTitle.2')}
							</IntroduceTitle>
							<IntroduceDescription>
								{t('SolutionFourthSection.introduceDescription.2')}
							</IntroduceDescription>
							<IntroduceDescriptionList>
								{t('SolutionFourthSection.introduceDescriptionList.2')}
							</IntroduceDescriptionList>
						</IntroduceWrapper>
					</ImageAndIntroduceBox>

					<ImageAndIntroduceBox>
						<IntroduceWrapper>
							<IntroduceTitle>
								{t('SolutionFourthSection.introduceTitle.3')}
							</IntroduceTitle>
							<IntroduceDescription>
								{t('SolutionFourthSection.introduceDescription.3')}
							</IntroduceDescription>
							<IntroduceDescriptionList>
								{t('SolutionFourthSection.introduceDescriptionList.3')}
							</IntroduceDescriptionList>
						</IntroduceWrapper>
					</ImageAndIntroduceBox>
				</ContentsContainer>
				<ButtonContainer>
					<MoreButton link="https://blog.tesser.io/medical-llm-ontologia/" text={t('SolutionFourthSection.button.1')}/>
				</ButtonContainer>
			</Block>
		</FullWidth>
	)
}

const FullWidth = styled.section`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const Block = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		width: 1008px;
		padding-top: 80px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
		padding-top: 86px;
	}
	@media (max-width: 768px) {
		width: 320px;
		padding-top: 115px;
	}
`
const ContentsContainer = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		flex-direction: row;
		flex-wrap: wrap;
		gap: 80px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		flex-direction: column;
		gap: 86px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 64px;
	}
`

const ImageAndIntroduceBox = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		flex-basis: 46%;
		flex-direction: row;
		gap: 32px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		flex-direction: column;
		gap: 24px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 24px;
	}
`


const IntroduceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`

const IntroduceTitle = styled.div`
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 32px;
		line-height: 40px; /* 125% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 28px;
		line-height: 36px; /* 128.571% */
	}
	@media (max-width: 768px) {
		font-size: 24px;
		line-height: 32px; /* 133.333% */
	}
`

const IntroduceDescription = styled.div`
	font-weight: 400;

	@media (min-width: 1440px) {
		font-size: 20px;
		line-height: 32px; /* 160% */
		padding-top: 24px;
		white-space: pre-line;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
		padding-top: 16px;
		max-width: 500px;
		white-space: pre-line;
	}
	@media (max-width: 768px) {
		font-size: 16px;
		line-height: 24px; /* 142.857% */
		padding-top: 16px;
	}
`

const IntroduceDescriptionList = styled.div`
	font-weight: 400;
	white-space: pre-line;

	@media (min-width: 1440px) {
		font-size: 18px;
		line-height: 36px;
		margin-top: 30px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 16px;
		line-height: 32px;
		margin-top: 24px;
	}
	@media (max-width: 768px) {
		font-size: 14px;
		line-height: 23px; /* 142.857% */
		margin-top: 15px;
	}
`

const ButtonContainer = styled.div`
	display: flex;
	width: 100%;

	@media (min-width: 1440px) {
		justify-content: center;
		margin-top: 80px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		justify-content: flex-start;
		margin-top: 86px;
	}
	@media (max-width: 768px) {
		justify-content: center;
		margin-top: 50px;
	}
`

export default SolutionFourthSection
