import React, { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

import { buttonHover } from '../../lib/styles/utils'

type ButtonSize = 'sm' | 'md' | 'lg'

type ButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, 'size'> & {
	color?: string
	size?: ButtonSize
	icon?: React.ReactNode
	fluid?: boolean
	className?: string
	fontWeight?: string
}

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: 'primary' | 'secondary'
}

function Button({ children, className }: ButtonProps) {
	return <ButtonBlock className={className}>{children}</ButtonBlock>
}

const ButtonBlock = styled.button`
	background-color: black;
	color: white;
	padding: 1rem 1.75rem;
	border-radius: 5rem;
	display: flex;
	align-items: center;
	font-size: 1.25rem;

	${buttonHover}
`

export default Button
