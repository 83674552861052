import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { graphql, useStaticQuery } from 'gatsby'
import Image from '../common/Image'
import Arrow from '../../assets/icons/chevron.svg'
import LinkIcon from '../../assets/icons/link.svg'

function SolutionSixthSection() {
	const { t } = useTranslation()

	const data = useStaticQuery(graphql`
		query {
			SolutionImage9: file(
				relativePath: { eq: "images/solution/SolutionImage9.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			SolutionImage10: file(
				relativePath: { eq: "images/solution/SolutionImage10.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			SolutionImage11: file(
				relativePath: { eq: "images/solution/SolutionImage11.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<FullWidth>
			<Block>
				<HeadingContainer>
					<HeadingTitle>
						{t('SolutionSixthSection.headingTitle.1')}
					</HeadingTitle>
					<HeadingDescription>
						{t('SolutionSixthSection.headingDescription.1')}
					</HeadingDescription>
				</HeadingContainer>
				<ContentsContainer>
					<ContentsBox>
						<ContentsImageWrap>
							<Image data={{ placeholderImage: data.SolutionImage9 }} />
						</ContentsImageWrap>
						<ContentsDescription
							href="https://blog.naver.com/tesserinc/222961361800"
							target="_blank"
						>
							{t('SolutionSixthSection.contentDescription.1')}
							<Link>
								<LinkIcon />
							</Link>
						</ContentsDescription>
					</ContentsBox>
					<ContentsBox>
						<ContentsImageWrap>
							<Image data={{ placeholderImage: data.SolutionImage10 }} />
						</ContentsImageWrap>
						<ContentsDescription
							href="https://blog.naver.com/tesserinc/223014538600"
							target="_blank"
						>
							{t('SolutionSixthSection.contentDescription.2')}
							<Link>
								<LinkIcon />
							</Link>
						</ContentsDescription>
					</ContentsBox>
					<ContentsBox>
						<ContentsImageWrap>
							<Image data={{ placeholderImage: data.SolutionImage11 }} />
						</ContentsImageWrap>
						<ContentsDescription
							href="https://blog.tesser.io/pathology-slide-image-segmentation-byeongri-seulraideu-imiji/"
							target="_blank"
						>
							{t('SolutionSixthSection.contentDescription.3')}
							<Link>
								<LinkIcon />
							</Link>
						</ContentsDescription>
					</ContentsBox>
				</ContentsContainer>
				<ButtonContainer>
					<MoreButton
						href="https://blog.naver.com/PostList.naver?blogId=tesserinc&from=postList&categoryNo=12"
						target="_blank"
					>
						{/** @TEMP : 링크 보류 */}
						<MoreButtonText>
							{t('SolutionSixthSection.button.1')}
						</MoreButtonText>
						<Arrow />
					</MoreButton>
				</ButtonContainer>
			</Block>
		</FullWidth>
	)
}

const FullWidth = styled.section`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const Block = styled.div`
	@media (min-width: 1440px) {
		width: 1112px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		padding-top: 272px;
		gap: 48px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 168px;
		gap: 24px;
	}
	@media (max-width: 768px) {
		padding-top: 176px;
		gap: 24px;
	}
`

const HeadingTitle = styled.div`
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 60px;
		line-height: 72px; /* 120% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		line-height: 56px; /* 116.667% */
	}
	@media (max-width: 768px) {
		font-size: 36px;
		line-height: 44px; /* 122.222% */
		white-space: pre-line;

		padding-right: 70px;
	}
`

const HeadingDescription = styled.div`
	font-weight: 400;

	@media (min-width: 1440px) {
		font-size: 20px;
		line-height: 32px; /* 160% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
	@media (max-width: 768px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
`

const ContentsContainer = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		flex-direction: row;
		padding-top: 80px;
		gap: 32px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		flex-direction: column;
		padding-top: 86px;
		gap: 86px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		padding-top: 86px;
		gap: 50px;
	}
`

const ContentsBox = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		gap: 20px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		gap: 24px;
	}
	@media (max-width: 768px) {
		gap: 24px;
	}
`

const ContentsImageWrap = styled.div`
	@media (min-width: 1440px) {
		width: 364px;
		height: 364px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 445px;
		height: 445px;
	}
	@media (max-width: 768px) {
		width: 305px;
		height: 305px;
	}
`

const ContentsDescription = styled.a`
	position: relative;
	font-weight: 700;
	white-space: pre-line;

	&:hover {
		opacity: 0.65;
	}

	@media (min-width: 1440px) {
		font-size: 28px;
		line-height: 36px; /* 128.571% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 28px;
		line-height: 36px; /* 128.571% */
		padding-right: 240px;
	}
	@media (max-width: 768px) {
		font-size: 20px;
		line-height: 28px; /* 140% */
		width: 93%;
	}
`

const Link = styled.div`
	svg {
		position: absolute;
		width: 8px;
		height: 8px;
		opacity: 0.5;
		@media (min-width: 1440px) {
			top: 0;
			left: ${364 - 3}px;
		}
		@media (min-width: 768px) and (max-width: 1440px) {
			top: 0;
			left: ${445 - 1}px;
		}
		@media (max-width: 768px) {
			top: 0;
			left: ${305 - 4}px;
		}
	}
`

const ButtonContainer = styled.div`
	display: flex;
	width: 100%;

	@media (min-width: 1440px) {
		justify-content: center;
		margin-top: 151px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		justify-content: flex-start;
		margin-top: 96px;
	}
	@media (max-width: 768px) {
		justify-content: center;
		margin-top: 86px;
	}
`

const MoreButton = styled.a`
	cursor: pointer;
	display: flex;
	padding: 16px 24px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	background-color: black;
	border-radius: 80px;
	width: max-content;
`

const MoreButtonText = styled.div`
	font-weight: 600;
	line-height: 132%; /* 21.12px */
	color: white;

	@media (min-width: 1440px) {
		font-size: 16px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 14px;
	}
	@media (max-width: 768px) {
		font-size: 12px;
	}
`

export default SolutionSixthSection
