import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { graphql, useStaticQuery } from 'gatsby'
import Image from '../common/Image'

function SolutionThirdSection() {
	const { t } = useTranslation()

	const data = useStaticQuery(graphql`
		query {
			SolutionImage2: file(
				relativePath: { eq: "images/solution/SolutionImage2.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<Background>
			<FullWidth>
				{/* <Block> */}
				<HeadingContainer>
					<TitleContainer>
						<TitleBlack>{t('SolutionThirdSection.titleBlack.1')}</TitleBlack>
						<TitleGray>{t('SolutionThirdSection.titleGray.1')}</TitleGray>
						<br />
						<TitleBlack>{t('SolutionThirdSection.titleBlack.2')}</TitleBlack>
					</TitleContainer>
				</HeadingContainer>
				<ImageWrapper>
					<Image data={{ placeholderImage: data.SolutionImage2 }} />
				</ImageWrapper>
				<DescriptionContainer>
					<Min768Wrapper>
						<DescriptionText>
							{t('SolutionThirdSection.descriptionText.1')}
						</DescriptionText>
					</Min768Wrapper>
					<Max768Wrapper>
						<DescriptionText>
							{t('SolutionThirdSection.descriptionText.max768')}
						</DescriptionText>
					</Max768Wrapper>
				</DescriptionContainer>
				{/* </Block> */}
			</FullWidth>
		</Background>
	)
}

const Min768Wrapper = styled.div`
	@media (max-width: 768px) {
		display: none;
	}
`

const Max768Wrapper = styled.div`
	@media (min-width: 768px) {
		display: none;
	}
`

const Background = styled.section`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	width: 100vw;
`

const FullWidth = styled.div`
	margin: 0 auto;

	@media (min-width: 1440px) {
		width: 1008px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

// const FullWidth = styled.section`
// 	position: relative;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	width: 100%;
// `

// const Block = styled.div`
// 	@media (min-width: 1440px) {
// 		width: 1008px;
// 	}
// 	@media (min-width: 768px) and (max-width: 1440px) {
// 		width: 688px;
// 	}
// 	@media (max-width: 768px) {
// 		width: 320px;
// 	}
// `

const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		padding-top: 350px;
		gap: 48px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 246px;
		gap: 24px;
	}
	@media (max-width: 768px) {
		padding-top: 137px;
		gap: 24px;
	}
`

const TitleContainer = styled.div`
	@media (max-width: 768px) {
		white-space: pre-line;
	}
`

const TitleBlack = styled.span`
	color: black;
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 58px;
		line-height: 72px; /* 120% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		line-height: 56px; /* 116.667% */
	}
	@media (max-width: 768px) {
		font-size: 32px;
		line-height: 44px; /* 122.222% */
	}
`

const TitleGray = styled.span`
	color: #86868b;
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 60px;
		line-height: 72px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		line-height: 56px;
	}
	@media (max-width: 768px) {
		font-size: 32px;
		line-height: 44px;
	}
`

const DescriptionContainer = styled.div`
	@media (min-width: 1440px) {
		padding-top: 88px;
		padding-bottom: 60px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 64px;
	}
	@media (max-width: 768px) {
		padding-top: calc(66vw + 5px);
	}
`

const DescriptionText = styled.span`
	font-weight: 400;
	white-space: pre-line;

	@media (min-width: 1440px) {
		font-size: 24px;
		line-height: 40px; /* 128% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
	@media (max-width: 768px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
		white-space: pre-line;
	}
`

const ImageWrapper = styled.div`
	@media (min-width: 1440px) {
		padding-top: 48px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 48px;
	}
	@media (max-width: 768px) {
		position: absolute;
		left: 0px;
		padding-top: 24px;
		width: 100.5vw; /* 화면 너비와 동일하게 설정 */
	}
`

export default SolutionThirdSection
