import React, { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import RightArrow from '../../assets/icons/arrow-right.svg'
import Button from './Button'

interface IButtonWithIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode
	disabled?: boolean
	color?: string
	fontSize?: string
	fontWeight?: string
}

function ButtonWithIcon({
	children,
	disabled,
	color,
	fontSize,
	fontWeight,
}: IButtonWithIconProps) {
	return (
		<Block
			disabled={disabled}
			color={color}
			fontSize={fontSize}
			fontWeight={fontWeight}
		>
			{children} <RightArrow />
		</Block>
	)
}

const Block = styled(Button)<{
	disabled?: boolean
	color?: string
	fontSize?: string
	fontWeight?: string
}>`
	white-space: nowrap;
	svg {
		margin-left: 0.5rem;
		width: 1rem;
		path {
			fill: white;
		}
	}

	${(props) =>
		props.fontSize &&
		css`
			font-size: ${props.fontSize};
		`}
	${(props) =>
		props.fontWeight &&
		css`
			font-weight: ${props.fontWeight};
		`}
	${(props) =>
		props.disabled &&
		css`
			background-color: #d0d0d0;
			cursor: not-allowed;

			&:hover {
				background-color: #d0d0d0;
			}
		`}

	${(props) =>
		props.color === 'white' &&
		css`
			background-color: white;
			color: black;
			margin-top: 2rem;
			&:hover {
				background-color: #d0d0d0;
			}
			svg {
				path {
					fill: black;
				}
			}
		`}


	${(props) =>
		props.color === 'gray' &&
		css`
			background-color: #aaa;
			color: white;
			&:hover {
				background-color: #d0d0d0;
			}
			svg {
				path {
					fill: #fff;
				}
			}
		`}

		${(props) =>
		props.color === 'pink' &&
		css`
			background-color: #ff3ab5;
			color: white;
			border-radius: 0;
			&:hover {
				background-color: #f76fc3;
			}
			svg {
				path {
					fill: #fff;
				}
			}
		`}
`

export default ButtonWithIcon
